(async () => {
  const copyButton = document.querySelector("#copy_ca");

  const caToast = document.querySelector("#ca_toast");
    
  copyButton.addEventListener("click", () => {
    navigator.clipboard.writeText("4T4Pvfwkav7CfX6rh31LJRSjijsorqnvHw49RyVRpump");

    caToast.classList.add("show");

    setTimeout(() => {
      caToast.classList.remove("show");
    }, 2000);
  });

  const swapButton = document.querySelector("#open_terminal");

  swapButton.addEventListener("click", () => {
    const config = {
      endpoint: 'https://thirsty-pandora-fast-mainnet.helius-rpc.com',
      formProps: {
      fixedOutputMint: true,
      swapMode: 'ExactIn',
      initialOutputMint: '4T4Pvfwkav7CfX6rh31LJRSjijsorqnvHw49RyVRpump',
      },
    };

    if (typeof window !== 'undefined' && window.Jupiter) {
      window.Jupiter.init(config);
    }
  });
})();
